<template>
  <div class="col-12 mb-4">
    <div class="row">
      <div class="col-12 bold font17 normal-text-dark">
        {{ stepNumber }}. Enter Donation Amount<span v-if="hasMultipleDonationTypes">s</span>:
      </div>
      <div class="col-12 col-md-11 font15 my-1" v-if="hasMultipleDonationTypes">
        Choose how you would like to split your intended donation amount across the eligible donation types:
      </div>
      <div class="col-12 p-0">
        <div class="row align-items-center">
          <div class="col-12">
            <div class="row mx-0 mt-3">
              <div class="col-6 col-lg-4 col-xl-3 mb-3" v-for="(type, key) in theProject.projectDonationTypeMaps" :key="key">
                <div class="row m-0">
                  <div class="col-12 p-0" v-if="donationTypesValue[key]">
                    <CurrencyField type="text" placeholder="0.00" id="amount" color="goldgold" v-model="donationTypesValue[key].amount" :currencySymbol="currency" @input="updateAmount(key)" />
                  </div>
                </div>
                <div class="row m-0">
                  <div class="col-12 bold green-text-dark font15 px-1">
                    {{type.donationType.description}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mt-4 mb-3" v-if="hasMultipleDonationTypes">
            <div class="row m-0">
              <div class="col-12">
                <div class="row m-0">
                  <div class="col-12 bold green-text-dark font15 px-1">
                    Donation Total
                  </div>
                  <div class="col-12 total px-1">
                    {{displayTotalAndCurrency}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 font10">
            <div class="row mx-0">
              <div class="col-12" :class="{'error-text': errorMessage !== 'Ready to Donate'}">
                {{errorMessage}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    CurrencyField: defineAsyncComponent(() => import('@/components/CurrencyField.vue'))
  },
  props: ['donationTypes', 'theProject', 'stepNumber', 'errorMessage', 'currency', 'displayTotalAndCurrency', 'hasMultipleDonationTypes'],
  emits: ['update:donationTypes', 'updateAmount'],
  name: 'EnterDonationAmounts',
  computed: {
    donationTypesValue: {
      get () {
        return this.donationTypes
      },
      set (val) {
        console.log('SETDSDS', val)
        this.$emit('update:donationTypes', val)
        setTimeout(() => {
          this.$emit('updateAmount')
        }, 20)
      }
    }
  },
  methods: {
    updateAmount (key) {
      setTimeout(() => {
        this.$emit('updateAmount', key)
      }, 50)
    }
  }
}
</script>

<style scoped>
.total {
  font-family: "quicksand", Sans-serif;
  font-size: 45px;
  font-weight: 400;
  color: var(--green-color);
  line-height: 1;
}

</style>
